import { NavBar } from "./navbar";
import {} from "./styled";

export const Projects = () => {
  return (
    <>
      <NavBar />
    </>
  );
};
